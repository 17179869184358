// biome-ignore lint/style/useImportType: <explanation>
import * as React from 'react';
import type { VoidPageProps } from '../../utils/graphql';
import ExpertisePage from '../../components/expertises/ExpertisePage';

const ArtificialIntelligence: React.VoidFunctionComponent<VoidPageProps> = ({ pageContext }) => (
  <ExpertisePage offer="academy" expertise="artificial-intelligence" pageContext={pageContext} />
);

export default ArtificialIntelligence;
